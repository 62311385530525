<script lang="ts" setup>
  import { Text } from '@bt/design-system'
  import { useFetchInvestorEvents } from '@/composables/useFetchInvestorEvents'

  const { fetchInvestorEvents } = useFetchInvestorEvents()

  const { locale } = useI18n()
  const investorEvents = ref()

  const props = defineProps<{
    blok: {
      title: string
      buttonTitle: string
      href: Object
      target: string
      showDivider: boolean
      eventCount: number
    }
  }>()

  watch(
    () => props.blok.buttonTitle,
    () => {
      buttonData.value = getButtonData()
    }
  )

  const getButtonData = () => {
    return {
      title: props.blok.buttonTitle,
      href: props.blok.href,
      target: props.blok.target
    }
  }

  const buttonData = ref(getButtonData())

  onMounted(() => {
    getEvents()
  })

  const getEvents = async () => {
    investorEvents.value = await fetchInvestorEvents(locale.value, undefined, Number(props.blok.eventCount), true)
  }
</script>

<template>
  <div
    v-if="investorEvents?.events?.futureEvents"
    class="upcoming-events-banner-wrapper"
  >
    <div class="block-title">
      <Text
        :text="props.blok.title"
        type="h2"
      />
    </div>
    <div class="upcoming-events-wrapper">
      <EventCards :blok="investorEvents.events.futureEvents" />
    </div>
    <ButtonFullWidth :blok="buttonData" />
    <BlockDivider v-if="props.blok.showDivider" />
  </div>
</template>

<style lang="scss" scoped>
  @import '@/assets/styles/common.scss';

  .upcoming-events-wrapper {
    margin-bottom: rem(24);

    @include desktop-up {
      margin-bottom: rem(40);
    }
  }
</style>
